.mobile {
    height: 100%;
    width: 100%;
    background-image: url('../../static/images/mobile/bg.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: -10vh 0 0 0;
    position: relative;
    text-align: center;
    overflow-y: scroll;

    .title {
        color: #E30B20;
        font-family: 'Douyin Sans';
        font-size: 8.5vw;
        padding-top: 1rem;
    }

    .logo {
        display: block;
        margin: auto;
        height: 4rem;
        padding-top: 2rem;
    }

    .btn-box {
        display: flex;
        padding: 2rem 2rem 0 2rem;

        button {
            flex: 1;
            height: 3.7rem;
            border-radius: 3.6rem;
            border: none;
            background-color: #E8B862;
            box-shadow: 0px 4px 10px 0px rgba(232, 184, 98, 0.502);
            color: white;
            font-size: 1.2rem;
        }

        button:nth-child(2) {
            margin-left: 1rem;
            background-color: #E30B20;
            box-shadow: 0px 4.14px 10.35px 0px rgba(227, 11, 32, 0.5);
        }
    }

    .tips {
        font-size: 5vw;
        padding-top: .8rem;
    }

    .mobile-model {
        width: 92vw;
        padding-top: 3rem;
    }

    // .customer-service {
    //     height: 35vh;
    //     position: absolute;
    //     left: calc(35vh/2);
    //     bottom: 5vh;
    // }

    .internet-content-provider {
        font-size: .8rem;
        color: #666666;
        // position: absolute;
        // left: 0;
        // bottom: 0;
        display: flex;
        padding: .5rem;
        width: calc(100vw - 1rem);
        justify-content: center;
        margin-top: 5rem;

        a {
            text-decoration: none;
            color: #666666;
            padding-right: 1em;
        }

        .contacter {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}