.pc {
    width: 100%;
    height: 100%;
    background-image: url('../../static/images/pc/bg.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: relative;

    .header {
        padding: 3vh 0 0 6vh;
        display: flex;
        align-items: center;

        .logo {
            height: 9vh;
        }
    }

    .content {
        position: absolute;
        right: 0;
        bottom: -11vh;
        display: flex;
        flex-direction: row-reverse;

        .mobile-model {
            height: 100vh;
            margin-right: 15vw;
            margin-left: 8vw;
        }

        .title {
            padding-top: 13vh;
            font-size: 3vw;
            font-weight: bold;
            font-family: 'Douyin Sans';
            color: #E30B20;
        }

        .introduce {
            font-size: 1.5vw;
            font-weight: bold;
        }

        .QR-code-box {
            padding-top: 13vh;
            font-size: 1.28vw;
            display: flex;
            align-items: center;

            .tips {
                display: inline-block;
                border: solid .15vw #333333;
                padding: .6vw 1vw;
                font-size: .8vw;
                margin-top: 4vh;
                border-radius: .2vw;

                .arrows {
                    height: .75em;
                    margin-left: .5em;
                }
            }

            .QR_code {
                height: 18vh;
                margin-left: 3vw;
            }
        }
    }

    .footer {
        color: #666666;
        font-size: .7vw;
        position: absolute;
        left: 1vw;
        bottom: 1vh;
        line-height: 2em;

        span {
            display: inline-block;
            margin-right: .5em;
        }

        .contacter {
            text-decoration: underline;
            cursor: pointer;
        }

        .customer-service {
            height: 35vh;
            opacity: 0;
        }

        .contacter:hover+.customer-service {
            opacity: 1;
        }

        .inline {
            display: flex;
            align-items: center;
            color: #666666;
            text-decoration: none;

            .icon {
                height: 1.1em;
                margin-right: .2em;
            }
        }
    }
}